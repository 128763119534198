



















import DynamicResolver from "@/components/explore/DynamicResolver.vue";
import { ArrayWrapper } from "@/lib-on-fhir";
import { defineComponent } from "vue-demi";

export default defineComponent({
  props: {
    element: {
      type: ArrayWrapper,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    errorStackSize: {
      type: Number,
      required: true,
    },
  },
  components: {
    DynamicResolver,
  },
});
